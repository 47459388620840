import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { MatDialog} from '@angular/compiler';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';







@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    //MatDialog,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  //   AgmCoreModule.forRoot({
  //   //   // apiKey: 'AIzaSyASyYRBZmULmrmw_P9kgr7_266OhFNinPA',
  //   //   // apiKey: 'AIzaSyCbZ3MorhJBYmyROMf9EErEhGrONBhpGS4',
  //   //   // apiKey: 'AIzaSyAl7LJSm7eoe7KxS5HR6qVzBvGslrocLyQ',//firsmedie
  //   //   // apiKey: 'AIzaSyCDf4xTvd0Fg7R0-gblX2v7o8Qa0Bxpkyw',
  //   //   // apiKey: 'AIzaSyCoEm1IUA3RLCgleQN8Mbo_PIIP_2Y6cs0',//pinjam
  //        apiKey: 'AIzaSyBIknLayK22qvahC10EC2Bb6zNZy1W_FXc',//ko Hen
  //   //   // apiKey: 'AIzaSyBWyQQ1N9nfdMpmTHIUBj1XJx7Sr3TeT6I',//Om Michael
  //     language: 'en',
  //     libraries: ['geometry','places']
  //    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
